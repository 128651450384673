import type {
  TNAPIBody,
  TNAPIQuery,
  TNAPINormalResponse,
  TNAPIPathParams
} from '~/utils/napi-helpers'

function updateCompany(
  slug: string,
  payload: TNAPIBody<'/napis/v1/companies/{company_slug}/', 'put'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce(
    '/napis/v1/companies/{company_slug}/',
    'put',
    {
      company_slug: slug
    },
    undefined,
    payload
  )
}

function createCompany(payload: TNAPIBody<'/napis/v1/companies/', 'post'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/companies/', 'post', undefined, undefined, payload)
}

function getCompanyActivePlan(id: string) {
  const { openAPIFetchOnce } = useAuthFetch()

  return openAPIFetchOnce('/napis/v1/hr-financial/active-plan/', 'get', undefined, {
    company__id_slug: id
  })
}

function activateCompanyPlan(
  requestBody: TNAPIBody<'/napis/v1/hr-financial/plan/activate/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()

  return openAPIFetchOnce(
    '/napis/v1/hr-financial/plan/activate/',
    'post',
    undefined,
    undefined,
    requestBody
  )
}

function adminAssignCredit(
  requestBody: TNAPIBody<'/napis/v1/hr-financial/credit/admin-assign/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()

  return openAPIFetchOnce(
    '/napis/v1/hr-financial/credit/admin-assign/',
    'post',
    undefined,
    undefined,
    requestBody
  )
}

function getPPPPurchaseLink(
  requestBody: TNAPIBody<'/napis/v1/hr-financial/credit/ppp-purchase/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()

  return openAPIFetchOnce(
    '/napis/v1/hr-financial/credit/ppp-purchase/',
    'post',
    undefined,
    undefined,
    requestBody
  )
}

function getCreditInvoice(
  pathParams: TNAPIPathParams<'/napis/v1/hr-financial/credit-invoice/{invoice_id_slug}/', 'get'>
) {
  const { openAPIFetchOnce } = useAuthFetch()

  return openAPIFetchOnce(
    '/napis/v1/hr-financial/credit-invoice/{invoice_id_slug}/',
    'get',
    pathParams
  )
}

export function sitemapListCompanyOnce(
  query: TNAPIQuery<'/napis/v1/companies/', 'get'>
): Promise<TNAPINormalResponse<'/napis/v1/companies/', 'get'>> {
  const { openAPIFetchOnce } = useAuthFetch()

  const method = 'get'
  const path = '/napis/v1/companies/'

  const pathParams = undefined

  return openAPIFetchOnce(path, method, pathParams, query)
}

function listCompanyOnce(query: TNAPIQuery<'/napis/v1/companies/', 'get'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/companies/', 'get', undefined, query)
}

function listCompanyReactive(
  query: TNAPIQuery<'/napis/v1/companies/', 'get'>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
) {
  const { openApiReactiveList } = useAuthFetch()

  return openApiReactiveList(
    '/napis/v1/companies/',
    undefined,
    query,
    asyncDataOptions,
    oFetchOptions
  )
}

function getCompaniesByLeadSourceStat(
  activeOnly: boolean,
  todayOnly: boolean
): Promise<TNAPINormalResponse<'/napis/v1/stats/companies-by-lead-source/', 'get'>> {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/stats/companies-by-lead-source/'
  const method = 'get'
  const pathParams = undefined
  const query = {
    active_only: activeOnly,
    today_only: todayOnly
  }

  return openAPIFetchOnce(path, method, pathParams, query)
}

function getJobsByLeadSourceStat(
  activeOnly: boolean,
  todayOnly: boolean
): Promise<TNAPINormalResponse<'/napis/v1/stats/jobs-by-lead-source/', 'get'>> {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/stats/jobs-by-lead-source/'
  const method = 'get'
  const pathParams = undefined
  const query = {
    active_only: activeOnly,
    today_only: todayOnly
  }

  return openAPIFetchOnce(path, method, pathParams, query)
}

function getJobAppsByLeadSourceStat(
  activeJobsOnly: boolean,
  todayOnly: boolean
): Promise<TNAPINormalResponse<'/napis/v1/stats/job-applications-by-lead-source/', 'get'>> {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/stats/job-applications-by-lead-source/'
  const method = 'get'
  const pathParams = undefined
  const query = {
    active_jobs_only: activeJobsOnly,
    today_only: todayOnly
  }

  return openAPIFetchOnce(path, method, pathParams, query)
}

function getCompany(id: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/companies/{company_slug}/', 'get', {
    company_slug: id
  })
}

type UseCompanyReturnType = {
  createCompany: typeof createCompany
  listCompanyReactive: typeof listCompanyReactive
  listCompanyOnce: typeof listCompanyOnce
  sitemapListCompanyOnce: typeof sitemapListCompanyOnce
  updateCompany: typeof updateCompany
  getCompaniesByLeadSourceStat: typeof getCompaniesByLeadSourceStat
  getJobsByLeadSourceStat: typeof getJobsByLeadSourceStat
  getJobAppsByLeadSourceStat: typeof getJobAppsByLeadSourceStat
  getCompany: typeof getCompany
  getCompanyActivePlan: typeof getCompanyActivePlan
  activateCompanyPlan: typeof activateCompanyPlan
  adminAssignCredit: typeof adminAssignCredit
  getPPPPurchaseLink: typeof getPPPPurchaseLink
  getCreditInvoice: typeof getCreditInvoice
}

export function useCompany(): UseCompanyReturnType {
  return {
    createCompany,
    listCompanyReactive,
    listCompanyOnce,
    sitemapListCompanyOnce,
    getCompany,
    updateCompany,
    getCompaniesByLeadSourceStat,
    getJobsByLeadSourceStat,
    getJobAppsByLeadSourceStat,
    getCompanyActivePlan,
    activateCompanyPlan,
    adminAssignCredit,
    getPPPPurchaseLink,
    getCreditInvoice
  }
}
